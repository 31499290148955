<template>
    <div class="home animate__animated animate__fadeInDown">
        <slider class="carousel" animation="normal" width="100%" height="200px">
            <slider-item v-for="(i, index) in carouselImgList" :key="index">
                <img width="100%" @click="sliderClicked(index)" :src="i">
            </slider-item>
        </slider>
        <div class="flex top-navs">
            <div class="item " @click="recharge()">
                <img class="icon" src="@/assets/imgs/recharge_icon.png" alt="">
                <span>{{$t('home.navs.recharge')}}</span>
            </div>
            <div class="item" @click="withdrawl()">
                <img class="icon" src="@/assets/imgs/withdrawl_icon.png" alt="">
                <span>{{$t('home.navs.withdrawl')}}</span>
            </div>
            <div class="item" @click="$router.push({name:'tasks'})">
                <img class="icon" src="@/assets/imgs/diamond_icon.png" alt="">
                <span>{{$t('home.navs.diamond')}}</span>
            </div>
            <div class="item " @click="$router.push({name:'share'})">
                <img class="icon" src="@/assets/imgs/invite_icon.png" alt="">
                <span>{{$t('home.navs.invite')}}</span>
            </div>
            <div class="item ">
                <img class="icon" src="@/assets/imgs/coupon_icon.png" alt="">
                <span>{{$t('home.navs.coupon')}}</span>
            </div>
        </div>
        <!-- <div class="box mg30 notice">
            <img src="@/assets/svgs/notice.svg" alt="">
            <div class="txt" v-html="notice">
            </div>
        </div> -->
        <div class="mid-banner">
            <img class="banner-img" src="/statics/imgs/mid_banner2.png" alt="">
        </div>
        <div class="mg30 package-list">
            <a-row type="flex" justify="space-around">
                <a-col v-for="bag,i in bags" :key="'pkgitem'+i" :span="12">
                    <pkgitem :baginfo="bag" />
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
import Pkgitem from '../components/Pkgitem.vue';
export default {
    name: "Home",
    data() {
        return {
            carouselImgList: [
                '/statics/imgs/home_banner.png',
                '/statics/imgs/home_banner1.png',
                '/statics/imgs/home_banner2.png'
            ],
            notice: '',
            userinfo: {},
            bags:[],
        };
    },
    components: {
        Pkgitem
    },
    mounted() {
        this.$store.commit('setShowNav', true);
        this.getUserinfo();
        this.getBags();
    },
    methods: {
        getNotice(){
            let _this = this
            this.$http.call(this.$http.api.policy,{
                data:{id: 4}
            }).then(function(res){
                console.log(res.data.data.content)
                _this.notice = res.data.data.content;
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                
            });
            
        },
        getBags(){
            let _this = this
            this.$http.call(this.$http.api.bags).then(function(res){
                console.log(res.data.data)
                _this.bags = res.data.data;
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                
            });
            
        },
        getUserinfo() {
            let _this = this
            this.$http.call(this.$http.api.userinfo).then(res=>{
                // console.log(res);
                _this.userinfo = res.data.data;
                _this.$store.commit('setUserinfo', res.data.data);
                // _this.getNotice();
            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        formatPhone(str){
            return str.substring(0,3)+'****'+str.substring(str.length-3,str.length);
        },
        sliderClicked(id){
            console.log(id)
        },
        goWeb(url){
            window.location.href=url;
        },
        withdrawl(){
            if(!this.userinfo.fund_password){
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowSetFundpassBox',true);
            }else{
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowWithdrawlBox',true);
            }
        },
        recharge(){
            this.$store.commit('setBtmBox',true);
            this.$store.commit('setShowRechargeBox',true);
        },
    },
};
</script>
<style lang="less" scoped>
.home {
    width:100%;
    padding: 15px;
    color: #666;
    .carousel{
        background: transparent;
        border-radius: 16px 16px 0 0;
        z-index: -1;
    }
    .notice{
        padding: 28px 15px;
        display: flex;
        align-items: flex-start;
        .txt{
            padding-left:15px;
            text-align: left;
        }
    }
    .box {
        margin-bottom: 24px;
        padding: 10px;
        background: @white;
        color: @bg-primary;
        .icon{
            height: 30px;
            padding-right: 15px;
        }
    }
    .top-navs{
        margin: 0 -15px;
        // width: 100%;
        .item{
            z-index: 9;
            position: relative;
            width: 20%;
            padding: 10px;
            justify-content: space-between;
            align-items: center;
            color: @white;
            font-weight: bold;
            img.icon{
                width: 80%;
            }
        }:first-child,:last-child{
            margin-top: -15px;
        }:nth-child(2),:nth-child(4){
            margin-top: -35px;
        }:nth-child(3){
            margin-top: -55px;
        }
        
    }
    .mid-banner{
        padding: 15px 0;
        .banner-img{
            width: 100%;
        }
    }
    .package-list{
        margin: 0 -15px;
    }
    .gains{
        border: 1px solid #fff;
        color: @white;
        border-radius: 10px;
        .title{
            border-bottom: 1px solid @gray;
            padding: 10px 0;
            font-weight: bold;
            div{
                padding: 0 10px;
            }
        }
        .gainslist{
            height: 400px;
            overflow: scroll;
            text-align: left;
            padding: 10px;
        }
    }
}



</style>
